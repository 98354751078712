footer.page-footer {
    background-color: #667490;
    background-color: #333639;
    background-color: #716D6D;
    background-color: #7B7C7B;
    background-color: #A2A2A2;
    background-color: #7B7B7B;
    background-color: #B4ABAD;
    background-color: #546C85;
    /* background-color: #708392; */
}