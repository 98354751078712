/* Overall page */

.clearfix {
    overflow: auto;
}

@font-face {
    font-family: LeagueSpartan;
    font-weight: bold;
    src: url("/assets/fonts/LeagueSpartan-Bold.otf") format("opentype");
}

body {
    /* color: #333333; */
    margin: 0 auto;
    padding: 0px;
}

body, html {
    height: 100%;
    margin: 0;
}

h1, h2, h3, ul {
    font-family: 'Georgia', Times, Times New Roman, serif;
}

.titleLanguages {
    color: #f26622;
    color: #111111;
    font-size: 32px;
    font-weight: 600;
    font-family: 'Stylish', sans-serif;
}

.footer_style {
    position: absolute;
    width: 100%;
    height: 3.5rem;
    bottom: 0rem;
    left: 0;
    text-align: center;
    padding: 20px 0 20px 0;
    color: #362b2b;
    font-family: 'Georgia', Times, Times New Roman, serif;
    font-size: 12px;
    opacity: .7;
    background-color: #DEE1E6;
    margin: 0rem !important;
    /* margin-top: 14rem !important; */
    /* overflow: scroll; */
}

/* ----------------------------------------------------------------------------- */

/* Header Navbar */

#navbarText {
    /* width: 10rem !important; */
    width: 100%;
    /* background-color: rgba(255, 255, 255, .4); */
    /* margin: 0 !important; */
    padding: 1rem;
    /* text-align: left; */
    padding: .6rem 10% 1.6rem 0 !important;
}

.headerLogo {
    z-index: 1000;
    position: absolute;
    top: 1rem;
    margin-left: 10%;
}

.headerLogo img {
    width: 158px;
    border-bottom: .001rem solid #BDBDBD;
}

.navCa {
    padding: 0rem 0rem 0rem 0rem;
    width: 100%;
    box-shadow: none !important;
}

.header {
    width: 100%;
    align-items: baseline;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    padding: 0;
    border: none !important;
    box-shadow: none !important;
}

.imgLogo{
    width: 100%;
}

.navbarMain {
    padding: 2.5rem 0 0rem 0 !important;
    /* background-color: rgba(255, 255, 255, .3); */
}

nav {
    background-color: rgba(255, 255, 255, .6);
    /* box-shadow: none !important; */
}

.navLink {
    font-size: .9rem;
    font-size: 1.2rem;
    /* font-weight: 600; */
    /* padding-top: .5rem; */
    /* font-family: 'Russo One', sans-serif; */
    /* font-family: 'Permanent Marker', cursive; */
    /* border-right: .1rem solid #BDBDBD; */
    /* font-family: 'Lobster', cursive; */
    /* font-family: 'Montserrat', sans-serif; */
    color: #FFFFFF;
    color: #363636;
    color: #323639;
    /* color: #494949; */
    /* color: #FFFFFF; */
    /* color: #474249; */
    color: #0E0D0B;
    color: #716D6D;
}

.navbar.center .navbar-inner {
    text-align: center;
}

.navbar.center .navbar-inner .nav {
    display: inline-block;
    float: none;
    vertical-align: top;
}

.navCa .navbar-nav>li>a:hover {
    color: #1976D2;
    color: #000000;
}

.navCa .navbar-nav>li>a:focus, .navCa .navbar-nav>li.active>a {
    color: #FFC107;
    color: #8E3472;
    color: #c1479c;
    color: #c1479c;
    /* color: #d34eaa; */
}

#navbarMain .navbar-nav {
    width: 100%;
    text-align: left;
}

.navbar button:focus {
    outline: none;
}

.menuIcon {
    width: 1.7rem;
    height: .2rem;
    background-color: #FFFFFF;
    background-color: #716D6D;
    margin: .3rem 0 .3rem 0;
}

.navCa .navbar-toggler {
    color: rgba(255, 255, 255, .5);
}

.btnHamburger {
    width: 100%;
    text-align: center;
}

#headerBrand {
    /* font-family: 'LeagueSpartan'; */
    font-size: 1.6rem;
    padding-top: .5rem;
    font-family: 'Russo One', sans-serif;
    /* font-family: 'Lobster', cursive; */
    font-family: 'Permanent Marker', cursive;
}

.headerTransform {
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
    background-color: rgba(255, 255, 255, .7);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12) !important;
    /* -webkit-transition: padding 500ms linear;
    -moz-transition: padding 500ms linear;
    -o-transition: padding 500ms linear;
    -ms-transition: padding 500ms linear;
    transition: padding 500ms linear; */
}

.header_content {
    margin-bottom: 20px;
    margin-top: 3rem;
    font-family: 'LeagueSpartan';
    font-family: 'Lobster', cursive;
    font-size: 2.8rem;
    /* font-family: 'Russo One', sans-serif; */
    /* font-family: 'Lobster', cursive; */
    font-family: 'Permanent Marker', cursive;
}

.startSubText {
    font-size: 1.1rem;
    /* font-family: 'Lobster';     */
    /* font-family: 'Russo One', sans-serif;  */
    font-family: 'Arvo', serif;
    /* font-family: 'Permanent Marker', cursive; */
}

/* ----------------------------------------------------------------------------- */

/* start/Home */

#start_image {
    width: 22rem;
    height: auto;
    border: 0rem solid gray;
}

.arrowImg {
    width: 8%;
    height: auto;
    opacity: 1;
    /* margin:20px 0; */
}

.arrowImg:hover {
    cursor: pointer;
}

.arrowContainer {
    min-width: 100% !important;
    text-align: center;
    align-items: baseline;
    position: absolute;
    top: 40rem;
    top: 80%;
    z-index: 10;
    opacity: .1;
}

.bg_image {
    position: relative;
    top: 13rem;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 70vh;
}

.start {
    margin: 0 auto;
}

#carouselExampleCaptions {
    margin: 0 auto;
    /* position: fixed; */
    top: 1rem;
    width: 100;
    /* height: 100%; */
}

/* ----------------------------------------------------------------------------- */

/* About me */

#about {
    max-width: 100%;
    margin: 0 auto;
    /* border: 1px solid #FFFFFF; */
    padding-top: 8rem
}

#about_image {
    /* float: left; */
    margin: 0 4rem 0 0;
    width: 100%;
    height: auto;
    border-radius: .4rem;
    padding-right: 2.5rem
}

.sumaryHeader {
    font-size: 2rem;
    color: #262F72;
    font-family: 'Fugaz One', cursive;
}

.sumary {
    margin-top: 2rem;
    font-size: 1.1rem;
    width: 90%;
    line-height: 1.8;
    color: #212121;
    color: #757575;
    font-family: 'Montserrat', sans-serif;
}

.rowBtn {
    margin: 3rem 0 0 0;
}

/* ----------------------------------------------------------------------------- */

/* Skills */

.skillsHeader {
    font-size: 2rem;
    color: #262F72;
    font-family: 'Fugaz One', cursive;
}

#skills {
    width: 75%;
    height: 100%;
    padding: 10% 0 0 0;
    margin: 0 auto;
}

.skills {
    /* background-color: #F2F2F2; */
    padding: 10% 0 0 0;
    margin-top: .7rem;
}

.skillsContainer {
    padding: 5% 0 10% 0;
}

.imgSkills {
    width: 80%;
    height: auto;
}

.imgLanguage {
    width: 6rem;
    height: 6rem;
    margin: 20px;
}

/* ----------------------------------------------------------------------------- */

/* Portfolio */

#portfolio {
    margin: 0;
    padding: 10% 0 0 0;
    /* padding: 120px 20px 0px 20px; */
    height: 100vh;
}

.card-header {
    color: #757575 !important;
    color: #BBDEFB !important;
    color: #FFC107 !important;
    background: #BBDEFB !important;
    background: #757575 !important;
    background-color: rgba(52, 44, 25, .7) !important;
    background-color: rgba(121, 127, 131, .7) !important;
    background-color: rgba(231, 233, 237, .7) !important;
    /* background: #797F83 !important;     */
    font-size: .8rem;
    /* padding-top: .5rem; */
    font-family: 'Russo One', sans-serif;
    color: #612294 !important;
    color: #842e69 !important;
    color: #f011e5 !important;
    color: #f05bed !important;
    color: rgb(245, 46, 239) !important;
    color: #6c5b5b !important;
}

/* .btn-link */

.btn-link, .btn-link:hover {
    text-decoration: none !important;
}

.portfolioHeader {
    font-size: 32px;
    font-weight: 600;
    /* margin-top: 10px */
}

.portfolioDescription {
    margin-top: 10px;
    font-size: 19px;
    width: 100%;
    line-height: 1.5;
    color: #333333;
}

.portfolioRow {
    /* margin-top: 1rem; */
    padding: 1rem;
}

#linkImg {
    width: 35px;
    height: 35px;
}

.portfolio_image {
    width: 100%;
    height: auto;
    margin: 0px 0;
    border: 1px solid #FFFFFF;
    -moz-box-shadow: 0 0 15px #cccccc;
    -webkit-box-shadow: 0 0 15px #ccc;
    box-shadow: 0 0 15px #ccc;
}

.cardCustom {
    background-color: #f8f8f8;
    width: 300px;
    margin-top: 20px;
}

.card-text {
    font-size: .85rem;
}

/* ----------------------------------------------------------------------------- */

/* Contact modal */

#contactModal {
    opacity: 0.9;
    background-color: #333a40;
}

.text_box {
    width: 100%;
    margin: 0px 0 20px 0;
}

/* ----------------------------------------------------------------------------- */

/* Media query */

@media (max-width: 992px) {
    .navbar {
        padding: 2.8rem 0rem 2.25rem 0 !important;
    }
    #navbarText {
        padding: 3rem 10% 1.6rem 2rem !important;
    }
    .headerLogo {
        margin-left: 3%;
    }
}

@media screen and (max-width: 640px) {
    #carouselExampleCaptions {
        margin: 0 auto;
        /* position: absolute; */
        top: 10.5rem;
        width: 46vh;
        /* margin-bottom: 20rem; */
    }
    .carouselImg {
        /* width: 700rem; */
        height: 50vh;
        width: 70vh;
    }
    .footer_style {
        margin-top: 22rem !important;
    }
    /* .bg_image {
        background-image: url(../img/main/slideimage1vertical.JPEG);
        position: relative;
        top: 9rem;
    } */
    #about {
        max-width: 100%;
        margin: 0 auto;
        padding-top: 8rem
    }
    #about_image {
        width: 100%;
        margin: 2rem 0 2rem 0;
        padding: 0;
    }
    .sumaryText {
        padding-left: 1rem;
    }
    .bg_image {
        background-size: 100vh 100vh;
    }
    .arrowImg {
        width: 10rem;
        height: auto;
        opacity: .8;
    }
}

@media screen and (max-width: 990px) {
    .footer_style {
        margin-top: 15.5rem !important;
    }
    #about {
        max-width: 100%;
        margin: 0 auto;
        padding-top: 8rem
    }
    #about_image {
        width: 100%;
        margin: 2rem 0 2rem 0;
        padding: 0;
    }
    .arrowImg {
        width: 10rem;
        height: auto;
        opacity: .8;
    }
}

@media screen and (max-width: 1229px) {
    .footer_style {
        /* margin-top: 22rem !important; */
    }
    #about {
        max-width: 100%;
        margin: 0 auto;
        padding-top: 8rem
    }
    #about_image {
        width: 100%;
        margin: 2rem 0 2rem 0;
        padding: 0;
    }
    .arrowImg {
        width: 10rem;
        height: auto;
        opacity: .8;
    }
}

/* ---------------------------------- */
 /* Navigation*/

 
 .scrolling-navbar {
    transition: background .5s ease-in-out, padding .5s ease-in-out;
}

.top-nav-collapse {
    background-color: #667490;
}

/* Necessary for full page carousel*/

html, body, .view {
    height: 100%;
}

/* Carousel*/

.carousel, .carousel-item, .carousel-item.active {
    height: 100vh;
}

.carousel-inner {
    height: 100%;
}

.carousel-indicators li{
    background-color: #c1479c;
}

.carousel-item:nth-child(1) {
   background-image: url("/img/carousel/slidemain_1.jpg");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center center;
}
.carousel-item:nth-child(2) {
   background-image: url("./img/carousel/slideimage_1.jpg");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center center;
}
.carousel-item:nth-child(3) {
    background-image: url("./img/carousel/slideimage_2.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.carousel-item:nth-child(4) {
    background-image: url("./img/carousel/slideimage_3.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.carousel-item:nth-child(5) {
    background-image: url("./img/carousel/slideimage_4.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.carousel-item:nth-child(6) {
    background-image: url("./img/carousel/slideimage_5.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.carousel-item:nth-child(7) {
    background-image: url("./img/carousel/slideimage_6.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.carousel-item:nth-child(8) {
    background-image: url("./img/carousel/slideimage_7.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.carousel-item:nth-child(9) {
    background-image: url("./img/carousel/slideimage_8.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.carousel-item:nth-child(10) {
    background-image: url("./img/carousel/slideimage_9.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.carousel-item:nth-child(11) {
    background-image: url("./img/carousel/slideimage_10.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

